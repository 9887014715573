import React, { useContext, useState, useEffect } from 'react';
import { pick, isNull } from 'lodash';
import {
  getCompanyByDomain,
  getPublicCompany,
  getCompanyByID,
} from 'util/rehive';
import { currentCompanySelector } from 'redux/auth/selectors';
import { useSelector } from 'react-redux';
import { enableTracking } from 'util/tracking';

const defaultConfig = {
  company: 'krillpay_test',
  apple_app_store_url:
    'https://apps.apple.com/us/app/rehive-wallet/id1371128319',
  android_play_store_url:
    'https://play.google.com/store/apps/details?id=com.rehivewallet&hl=en',
  url: 'https://app.rehive.com/',
  bitrefill: false,
  privacy_policy_url: 'https://rehive.com/privacy/',
  terms_and_conditions_url: 'https://rehive.com/end-user-terms/',
};

const greyLabelDomains = [
  'app.rehive.com',
  'app.staging.rehive.com',
  'localhost',
];

const ConfigurationContext = React.createContext();

function ConfigurationProvider({ children }) {
  const [config, setConfig] = useState(null);

  const company = useSelector(currentCompanySelector);
  const domain = window.location.hostname;
  // const domain = 'wallet2.s.tokenexplorer.com';

  const isGreyLabel = new RegExp(greyLabelDomains.join('|')).test(domain);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!isGreyLabel || !company?.id || config?.id === company?.id) return;

    getCompanyByID(company.id).then(resp => {
      getPublicCompany(company.id).then(resp2 => {
        setConfig({ ...defaultConfig, ...resp2?.data, ...resp });
      });
    });
  }, [company.id]);

  async function init() {
    if (isGreyLabel) {
      setConfig(defaultConfig);
      enableTracking();
    } else await fetchConfig({ domain });
  }

  async function fetchConfig({ domain }) {
    //const resp = await getCompanyByDomain(domain);
    const resp = await getCompanyByID(defaultConfig.company);

    if (!resp?.id) return setConfig({ status: 'error' });
    if (!resp?.config?.tracking?.disable) enableTracking();

    const resp2 = await getPublicCompany(resp.id);

    setConfig({
      ...resp,
      company: resp.id,
      ...(resp2?.data ?? {}),
    });
  }

  return (
    <ConfigurationContext.Provider
      value={{
        config: config ?? {},
        loading: isNull(config),
        isGreyLabel,
      }}>
      {children}
    </ConfigurationContext.Provider>
  );
}

function useConfiguration() {
  const context = useContext(ConfigurationContext);

  if (context === undefined)
    throw new Error(
      'useConfiguration must be used within a ConfigurationProvider',
    );

  return context;
}

export { ConfigurationContext, ConfigurationProvider, useConfiguration };
